import { config } from "@/helpers/config";

export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "Home",
        icon: "bx-home-circle",
		link: "/"
    },
    {
        id: 6,
        isLayout: true
    },
    {
        id: 7,
        label: "Agent",
        isTitle: true
    },
	{
        id: 8,
		label: "menuitems.tickets.list.tickets",
		link: "/ticket/list",
        icon: "bxs-coupon",
		badge: {
			variant: "danger",
			text: localStorage.getItem(config.storage.NEW_TICKET_COUNT)
		}
    },
	{
		id: 9,
		label: "menuitems.tickets.list.search",
		link: "/ticket/search",
		icon: "bx-search"
	},
	{
		id: 24,
		label: "Admin",
		isTitle: true
	},
    {
        id: 11,
        label: "Automatic reply",
		link: "/automation",
        icon: "bx-bot"
    },
	{
		id: 14,
		label: "Customer rating",
		icon: "bx-badge-check",
		subItems: [
			{
				id: 16,
				label: "Ratings",
				link: "/csat/ratings",
				parentId: 14
			},
			{
				id: 15,
				label: "Configuration",
				link: "/csat/configuration",
				parentId: 14
			}
		]
	},
	{
		id: 19,
		icon: "bxs-bar-chart-alt-2",
		label: "Charts",
		subItems: [
			{
				id: 20,
				label: "menuitems.charts.list.apex",
				link: "/charts/apex",
				parentId: 19
			},
			{
				id: 21,
				label: "menuitems.charts.list.chartjs",
				link: "/charts/chartjs",
				parentId: 19
			},
			{
				id: 22,
				label: "menuitems.charts.list.chartist",
				link: "/charts/chartist",
				parentId: 19
			},
			{
				id: 23,
				label: "menuitems.charts.list.echart",
				link: "/charts/echart",
				parentId: 19
			}
		]
	},
    {
        id: 25,
        label: "menuitems.settings.text",
        icon: "bx-cog",
        subItems: [
			{
				id: 26,
				label: "menuitems.settings.list.agents",
				link: "/settings/agents",
				parentId: 25
			},
			{
				id: 27,
				label: "Channels",
				link: "/settings/agents",
				parentId: 25
			},
			{
				id: 28,
				label: "menuitems.settings.list.requesttypes",
				link: "/settings/types",
				parentId: 25
			},
        	{
				id: 29,
				label: "menuitems.settings.list.general",
				link: "/settings/general",
				parentId: 25
			}
        ]
    },
    {
        id: 29,
        label: "Personal",
        isTitle: true
    },
    {
        id: 30,
        label: "Account",
        icon: "bx-user-circle",
		link: "/settings/agents"
    },
];

