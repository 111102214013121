<script>
import { Client } from '@stomp/stompjs'
import { config } from '@/helpers/config'

export default {
	name: 'ToastView',
	props: {},
	components: {},
	data() {
		return {
			client: null,
			toastCount: 0
		}
	},
	created() {
		this.webSocketConnect()
	},
	destroyed() {
		this.webSocketClose()
	},
	computed: {},
	mounted() {
		this.$root.$on('bv::toast::hidden', () => {
			this.toastCount--
			if (this.toastCount < 0) {
				this.toastCount = 0
			}
		})
	},
	methods: {
		webSocketConnect() {
			this.client = new Client({
				brokerURL: `${config.isSecure ? 'wss' : 'ws'}://${window.location.host}/stomp`,
				// debug(str) {
				// 	console.log(str);
				// },
				onStompError(frame) {
					// Will be invoked in case of error encountered at Broker
					// Bad login/passcode typically will cause an error
					// Complaint brokers will set `message` header with a brief message. Body may contain details.
					// Compliant brokers will terminate the connection after any error
					console.log('Broker reported error: ' + frame.headers['message'])
					console.log('Additional details: ' + frame.body)
				},
				onWebsocketClose() {
					console.log('onWebsocketClose')
					this.webSocketClose()
				},
				onDisconnect(frame) {
					console.log('onDisconnect', frame)
				},
				heartbeatIncoming: 40000,
				heartbeatOutgoing: 40000
			})
			this.client.activate()

			this.client.onConnect = (frame) => {
				console.log('onConnect', frame)
				this.subscriptions = []
				// Do something, all subscribes must be done is this callback
				// This is needed because this will be executed after a (re)connect
				this.subscriptions.push(this.client.subscribe(`/message/ticket`, this.handleNewTicketMessageResponse))
			}
		},
		webSocketClose() {
			this.subscriptions.forEach((subscription) => {
				subscription?.unsubscribe()
			})
			this.client?.deactivate()
			this.client = null
		},
		handleNewTicketMessageResponse(response) {
			if (response?.body) {
				this.makeToast(response.body)
			}
		},
		makeToast(ticketId, append = false) {
			if (this.toastCount >= config.maxToasts) {
				return
			}
			this.toastCount++
			// `This is toast number ${this.toastCount}`
			let routeData = this.$router.resolve({
				name: 'ticket',
				params: { id: ticketId }
			})
			this.$bvToast.toast(`New ticket with id: ${ticketId} arrived.`, {
				title: 'New ticket',
				autoHideDelay: 5000,
				variant: 'info',
				to: routeData.href,
				appendToast: append
			})
		}
	}
}
</script>

<template>
<div></div>
</template>
