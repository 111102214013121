<script>
import { layoutComputed } from '@/state/helpers'
import Vertical from './vertical'
import ToastView from '@/components/toast-view'

export default {
	components: { Vertical, ToastView },
	data() {
		return {}
	},
	created() {
		console.log('created')
	},
	computed: {
		...layoutComputed
	},
	methods: {}
}
</script>

<template>
	<div>
		<vertical>
			<RouterView />
			<slot />
		</vertical>
		<ToastView />
	</div>
</template>
