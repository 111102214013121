<script>
export default {
	name: 'Footer',
	components: { },
	watch: {},
	methods: {}
}

</script>

<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">{{ new Date().getFullYear() }} © Eco.</div>
        <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">Design & Develop by Eco</div>
        </div>
      </div>
    </div>
  </footer>
  <!-- end footer -->
</template>
