<script>
import simplebar from "simplebar-vue";

import SideNav from "./side-nav";

export default {
  components: { simplebar, SideNav },
};
</script>

<template>
  <div class="vertical-menu">
    <simplebar class="h-100">
      <SideNav />
    </simplebar>
  </div>
</template>

